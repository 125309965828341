@import "tailwindcss/base";

@font-face {
    font-family: 'Rubik-Black';
    src: url(fonts/Rubik-Black.ttf) format("truetype");
}
@font-face {
    font-family: 'Rubik-Bold';
    src: url(fonts/Rubik-Bold.ttf) format("truetype");
}
@font-face {
    font-family: 'Rubik-Italic';
    src: url(fonts/Rubik-Italic.ttf) format("truetype");
}
@font-face {
    font-family: 'Rubik-LightItalic';
    src: url(fonts/Rubik-LightItalic.ttf) format("truetype");
}
@font-face {
    font-family: 'Rubik-MediumItalic';
    src: url(fonts/Rubik-MediumItalic.ttf) format("truetype");
}
@font-face {
    font-family: 'Rubik-BlackItalic';
    src: url(fonts/Rubik-BlackItalic.ttf) format("truetype");
}
@font-face {
    font-family: 'Rubik-BoldItalic';
    src: url(fonts/Rubik-BoldItalic.ttf) format("truetype");
}
@font-face {
    font-family: 'Rubik-Light';
    src: url(fonts/Rubik-Light.ttf) format("truetype");
}
@font-face {
    font-family: 'Rubik-Medium';
    src: url(fonts/Rubik-Medium.ttf) format("truetype");
}
@font-face {
    font-family: 'Rubik-Regular';
    src: url(fonts/Rubik-Regular.ttf) format("truetype");
}

@import "tailwindcss/components";

@import "tailwindcss/utilities";

// icons price
@responsive {
    // icons price
    .price { position: absolute; top: 20px; right: 0; }
    .price .course-price { float: right; padding: 5px; text-align: center; line-height: 24px; }
    .price .course-price:after { content: ' '; position: absolute; width: 0; height: 0; right: 0; top: 100%; border-width: 5px 5px; border-style: solid; border-color: #b08809 transparent transparent #b08809;z-index: -99; }
    .price .course-price:before { content: ' '; position: absolute; width: 0; height: 0; left: -18px; top: 0; border-width: 17px 15px; border-style: solid; border-color: #f39132 #f39132 #f39132 transparent; }
    .price .course-price .amount { position: relative; }
    .price .course-price em { font-style: normal; color: #333; margin: 0 7px; font-size: 0.8em; font-weight: 500; }
    .price .course-price del { color: #333; }
    .price .course-price del .amount { color: #333; font-size: 0.8em; font-weight: normal; }
    .price .course-price.free-course .amount { font-size: 15px; }
    // clippy testimonial css3
    .border-testimonial {-webkit-clip-path: polygon(0 0, 100% 0%, 100% 50%, 0% 100%); clip-path: polygon(0 0, 100% 0%, 100% 50%, 0% 100%);}

    // add new class bs prb old js
    .d-none {display: none !important;}
    .d-block {display: block !important;}

    // Modal
    .modal {transition: opacity 0.25s ease;}
    .all.modal-active {overflow-x: hidden;overflow-y: visible !important;}
    .d-none-js{pointer-events: none;opacity: 0;}
    .d-felx-important {display: flex!important;}
    .calandar-modal-active {overflow-x: hidden;overflow-y: visible !important;}

}

.tooltip .tooltip-text {
    visibility: hidden;
    text-align: center;
    padding: 20px;
    position: absolute;
    z-index: 100;
}
.tooltip:hover .tooltip-text {
    visibility: visible;
}


// slider
@media only screen and (min-width: 2561px) {
    #autoheight {
        height: 1000px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

@media only screen and (max-width: 2560px) {
    #autoheight {
        height: 1000px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}


@media only screen and (max-width: 1440px) {
    #autoheight {
        height: 600px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}


@media only screen and (max-width: 1280px) {
    #autoheight {
        height: 625px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}


@media only screen and (max-width: 1024px) {
    #autoheight {
        height: 425px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}


@media only screen and (max-width: 768px) {
    #autoheight {
        height: 325px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

@media only screen and (max-width: 640px) {
    #autoheight {
        height: 300px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .testimonial-content {
        font-size: 14px;
    }
}

@media only screen and (max-width: 425px) {
    #autoheight {
        height: 185px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .testimonial-content {
        font-size: 12px;
    }
}


@media only screen and (max-width: 375px) {
    #autoheight {
        height: 165px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .testimonial-content {
        font-size: 10px;
    }
}

@media only screen and (max-width: 320px) {
    #autoheight {
        height: 150px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}
  ////

.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
  }
  
  .pagination .page-item .page-link {
    padding: .75rem;
    display: block;
    text-decoration: none;
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    background-color: #ffffff;
    color: #ed8936;
  }
  
  .pagination .page-item .page-link:hover {
    background-color: #f1f5f8;
  }
  
  .pagination .page-item:first-child .page-link {
    margin-right: 1.5rem;
    border-width: 1px;
    border-radius: 0.25rem;
  }
  
  .pagination .page-item:nth-child(2) .page-link {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-left-width: 1px;
  }
  
  .pagination .page-item:nth-last-child(2) .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-right-width: 1px;
  }
  
  .pagination .page-item:last-child .page-link {
    margin-left: 1.5rem;
    border-width: 1px;
    border-radius: 0.25rem;
  }
  
  .pagination .page-item.active .page-link {
    background-color: #ed8936;
    border-color: #ed8936;
    color: #ffffff;
  }
  
  .pagination .page-item.disabled .page-link {
    background-color: #f1f5f8;
    color: #8795a1;
  }
  